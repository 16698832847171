import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Menulog from "../images/svgs/menulog.svg"
import Ubereats from "../images/svgs/ubereats.svg"
import Doordash from "../images/svgs/doordash.svg"
import Logo from "../images/svgs/logo-svg.svg"

import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa"
import { BsChevronDown } from "react-icons/bs"

const Footer = () => {
  return (
    <>
      <footer className="section">
        <div className="container">
          <div className="md:flex md:flex-wrap md:-mx-4 py-6 md:pb-12">
            <div className="md:w-1/2 lg:w-1/3 pr-5 mb-2 mb-2">
              <div className="footer__logo">
                <Link to="/">
                  <Logo className="nav-logo" />
                  <span className="logo-text center">Life's Sweet</span>
                </Link>
              </div>
              <p>Perth's freshest Greek donuts(loukoumades).</p>
            </div>
            <div className="md:w-1/2 lg:w-1/4 mb-2">
              <h3>Navigation</h3>
              <ul className="footer__nav">
                <StaticQuery
                  query={graphql`
                    query MenuFooterQuery {
                      allWpMenu(filter: { id: { eq: "dGVybToxMTU=" } }) {
                        edges {
                          node {
                            id
                            menuItems {
                              nodes {
                                path
                                parentId
                                label
                                childItems {
                                  nodes {
                                    path
                                    label
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => (
                    <>
                      {data.allWpMenu.edges[0].node.menuItems.nodes
                        .filter(item => {
                          return item.parentId == null
                        })
                        .map((item, index) => (
                          <li
                            key={index}
                            className={
                              item.childItems.nodes.length !== 0
                                ? "dropdown"
                                : ""
                            }
                          >
                            <Link to={item.path}>
                              {item.label}
                              {item.childItems.nodes.length !== 0 ? (
                                <BsChevronDown />
                              ) : (
                                ""
                              )}
                            </Link>
                            {item.childItems.nodes.length !== 0 ? (
                              <ul className="dropdown__menu">
                                {item.childItems.nodes.map((item, index) => (
                                  <li
                                    key={index}
                                    className="dropdown__menu__item"
                                  >
                                    <Link to={item.path}>{item.label}</Link>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        ))}
                    </>
                  )}
                />
              </ul>
            </div>
            <div className="md:w-1/2 lg:w-1/3 pr-5 mb-2 mb-2">
              <h3>Contact Us</h3>
              <div className="footer__icon first">
                <a href="mailto:info@donutworry.com.au">
                  <FaEnvelope />
                  <span> info@donutworry.com.au </span>
                </a>
              </div>
              <div className="footer__icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/oqjm8bPgynFAnBfq9"
                >
                  <FaMapMarkerAlt />
                  <div className="addressBox">
                    <span className="addressBox__header">
                      East Victoria Park
                    </span>
                    <span className="addressBox__address">
                      865 Albany Highway,East Victoria Park
                    </span>
                  </div>
                </a>
              </div>
              <div className="footer__icon">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/q3cTGbotunxTEmBz8"
                >
                  <FaMapMarkerAlt />
                  <div className="addressBox">
                    <span className="addressBox__header">
                      Northbridge
                    </span>
                    <span className="addressBox__address">
                      261 William St, Northbridge WA 6003
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className="md:w-1/2 lg:w-1/12 logos mb-2 text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.ubereats.com/au/store/donut-worry/Sen9atzIRH6vNXp3cazfbg"
              >
                <Ubereats />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.menulog.com.au/restaurants-donut-worry-east-victoria-park-east-victoria/menu"
              >
                <Menulog />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.doordash.com/store/donut-worry-east-victoria-park-1057577/"
              >
                <Doordash />
              </a>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright">
        <div className="container flex flex-wrap md:flex-wrap justify-between ">
          <div className="copyText">
            <p>
              &copy; Copyright 2021 Donut Worry. All Rights Reserved. Site By{" "}
              <a href="https://www.wordofmouthagency.com.au">
                Word Of Mouth Agency
              </a>{" "}
            </p>
          </div>
          <div className="socials">
            <span
              target="_blank"
              rel="noreferrer"
              className="header__socials__icon"
            >
              <a href="https://www.facebook.com/donutworryau">
                <FaFacebookF />
              </a>
            </span>
            <span
              target="_blank"
              rel="noreferrer"
              className="header__socials__icon"
            >
              <a href="https://www.instagram.com/donutworryau/">
                <FaInstagram />
              </a>
            </span>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
