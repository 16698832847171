import React, { useState } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import logo from "../images/logo-svg.svg"
import { BsChevronDown } from "react-icons/bs"
import { FaInstagram, FaFacebookF } from "react-icons/fa"
import Logo from "../images/svgs/logo-svg.svg"

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
    <section className="header">
      <div className="header container">
        <div className="header__logo">
          <Link to="/">
            <Logo className="nav-logo" />
            {/* <img className="nav-logo" src={logo} alt="Donut Worry " /> */}
            <span className="logo-text center">Life's Sweet</span>
            {/* <span className="logo-text bottom">Life's Sweet</span> */}
          </Link>
        </div>
        <nav className="header__nav">
          <span
            aria-hidden="true"
            className={
              toggleMenu
                ? "header__nav__hamburger nav--active"
                : "header__nav__hamburger"
            }
            role="button"
            onClick={() => setToggleMenu(!toggleMenu)}
          >
            <span></span>
          </span>
          <div className="menu__wrapper">
            <ul className="main__nav">
              <StaticQuery
                query={graphql`
                  query MenuQuery {
                    allWpMenu(filter: { id: { eq: "dGVybTo1OA==" } }) {
                      edges {
                        node {
                          id
                          menuItems {
                            nodes {
                              path
                              parentId
                              label
                              cssClasses
                              childItems {
                                nodes {
                                  path
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <>
                    {data.allWpMenu.edges[0].node.menuItems.nodes
                      .filter(item => {
                        return item.parentId == null
                      })
                      .map((item, index) => (
                        <li
                          key={index}
                          className={
                            item.childItems.nodes.length !== 0
                              ? "dropdown"
                              : "" + item.cssClasses.length
                              ? item.cssClasses.join(" ")
                              : ""
                          }
                        >
                          <Link to={item.path}>
                            {item.label}
                            {item.childItems.nodes.length !== 0 ? (
                              <BsChevronDown />
                            ) : (
                              ""
                            )}
                          </Link>
                          {item.childItems.nodes.length !== 0 ? (
                            <ul className="dropdown__menu">
                              {item.childItems.nodes.map((item, index) => (
                                <li
                                  key={index}
                                  className="dropdown__menu__item"
                                >
                                  <Link to={item.path}>{item.label}</Link>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                  </>
                )}
              />
            </ul>
          </div>
        </nav>
        <div
          className={
            toggleMenu ? "header__socials nav--active" : "header__socials"
          }
        >
          <span className="header__socials__icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/donutworryau"
            >
              <FaFacebookF />
            </a>
          </span>
          <span className="header__socials__icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/donutworryau/"
            >
              <FaInstagram />
            </a>
          </span>

          <div className="header__socials__contact">
            <Link to="/contact" className="header__socials__contact__btn">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
